import React, { useEffect, useRef, useState } from "react";
import InputIcon from "../images/icons/dropdown-icon-input.svg";

const SelectElement = ({ id, forms, setForms, textareaRef }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && event.target !== buttonRef.current) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOpen = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu open/close
  };

  const selectOption = (optId) => {
    textareaRef.current.value = "";
    const updatedForms = forms.map((form) => (form.id === id ? { ...form, selectedOpt: optId } : form));
    setForms(updatedForms);
    setIsMenuOpen(false); // Close the menu when an option is clicked
  };

  const inputOption = () => {
    const item = forms.find((form) => form.id === id);
    const opt = item ? item.selectedOpt : "";

    if (opt === "opt-1") {
      return "Resource Pack";
    } else if (opt === "opt-2") {
      return "Behavior Pack";
    } else if (opt === "opt-3") {
      return "Skin Pack";
    } else {
      return "Select...";
    }
  };

  const isChecked = (optId) => {
    const item = forms.find((form) => form.id === id);
    return item && item.selectedOpt === optId;
  };

  return (
    <div className="pack-type__wrapper">
      <p className="pack-type__heading">Pack Type</p>
      <div ref={buttonRef} className="pack-type__input" onClick={handleOpen}>
        <p className="select__text">{inputOption()}</p>
        <img src={InputIcon} alt="Dropdown Icon" />
      </div>

      <div className={`pack-type__option-container ${isMenuOpen ? "block" : "hidden"}`} ref={menuRef}>
        <div className="pack-type__option" onClick={() => selectOption("opt-1")}>
          <div className="checkbox">{isChecked("opt-1") && <div className="checked"></div>}</div>
          <p>Resource Pack</p>
        </div>
        <div className="pack-type__option" onClick={() => selectOption("opt-2")}>
          <div className="checkbox">{isChecked("opt-2") && <div className="checked"></div>}</div>
          <p>Behavior Pack</p>
        </div>
        <div className="pack-type__option" onClick={() => selectOption("opt-3")}>
          <div className="checkbox">{isChecked("opt-3") && <div className="checked"></div>}</div>
          <p>Skin Pack</p>
        </div>
      </div>
    </div>
  );
};

export default SelectElement;
