import React from "react";
import BurgerIcon from "../images/icons/burger-icon.png";
import MangenLogo from "../images/logos/rfbmangen_logo.svg";

const Header = (/*{isNavMenuOpen, setIsNavMenuOpen, burgerIcon, mobileNavWrapper, mobileNav, wrappRef}*/) => {
  /*const handleOpenMenu = (e) => {
    e.stopPropagation();
    mobileNavWrapper.current.classList.toggle('open');
    
    if (mobileNavWrapper.current.classList.contains('open')) {
      setTimeout(() => {
        wrappRef.current.style.transform = 'translateX(0)';
        document.body.style.overflowY = 'hidden';
      }, 0);
    } 
  };*/

  return (
    <header>
      <div className="w-full flex items-center justify-center relative pt-8">
        {/*<img ref={burgerIcon} src={BurgerIcon} className="w-[40px] absolute left-8" onClick={handleOpenMenu}/>*/}
        <img className="w-[70px]" src={MangenLogo} />
      </div>
    </header>
  );
};

export default Header;
