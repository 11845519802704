import React from "react";
import DefaultIcon from "../images/icons/dropdown-icon-default.svg";
import ActiveIcon from "../images/icons/dropdown-icon-active.svg";
import HoverIcon from "../images/icons/dropdown-icon-hover.svg";

const AdvanceModeBtn = ({
  isAdvOpen,
  setIsAdvOpen,
  forms,
  setForms,
  textareaRef,
  addDepsRef,
  errorsRef,
  setErrorsActivated,
  displayErrors,
  scrollToTarget
}) => {
  const openAdv = () => {
    if (!isAdvOpen && displayErrors() === null) {
      textareaRef.current.value = "";
      setErrorsActivated(false);
      setIsAdvOpen(!isAdvOpen);
    } else if (!isAdvOpen && displayErrors() !== null) {
      textareaRef.current.value = "";
      scrollToTarget(errorsRef); // Ensure scrollToTarget is called correctly
      setErrorsActivated(true);
    } else if (isAdvOpen) {
      textareaRef.current.value = "";
      setForms([
        forms[0],
        {
          id: "m",
          type: "metadata",
          name: "",
          link: ""
        }
      ]);
      if (addDepsRef && addDepsRef.current) {
        addDepsRef.current.classList.remove("add-dependency-btn");
        addDepsRef.current.innerHTML = "<p>Create Dependency</p>";
      }
      setIsAdvOpen(!isAdvOpen);
    }
  };

  return (
    <div
      className="group advance-mode__link hover:bg-[#8e8e8e2b] hover:text-white active:bg-[#8e8e8e] active:text-black"
      onClick={openAdv}
    >
      <p>Advance Mode</p>
      <img className="dropdown-icon-default group-hover:hidden group-active:hidden" src={DefaultIcon} />
      <img className="dropdown-icon-active group-hover:hidden group-active:block" src={ActiveIcon} />
      <img className="dropdown-icon-hover group-hover:block group-active:hidden" src={HoverIcon} />
    </div>
  );
};

export default AdvanceModeBtn;
