import React, { useState, useRef, useEffect } from "react";

const GenerateSection = ({
  forms,
  setForms,
  isAdvOpen,
  setIsAdvOpen,
  errorsActivated,
  setErrorsActivated,
  textareaRef,
  addDepsRef,
  copiedRef,
  errorsRef,
  displayErrors
}) => {
  let manifest = {
    format_version: 1,
    header: {
      name: "RFB Spleef",
      uuid: "f601088e-74b0-4324-8475-d242b2613967",
      version: [1, 0, 0],
      description: "",
      min_engine_version: [1, 2, 6]
    },
    modules: [
      {
        type: "data",
        uuid: "f601088e-74b0-4324-8475-d242b2613967",
        version: [1, 0, 0],
        description: ""
      }
    ],
    dependencies: [
      {
        type: "data",
        uuid: "f601088e-74b0-4324-8475-d242b2613967",
        version: [1, 0, 0],
        description: ""
      }
    ],
    metadata: {
      authors: [""],
      url: ""
    }
  };

  const [generateClicked, setGenerateClicked] = useState(false);
  const [copyClicked, setCopyClicked] = useState(false);

  const generateUUID = () => {
    // Kyuiki was here! :D - 15:35 29/03/2024
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const inputOption = (opt) => {
    if (opt === "opt-1") {
      return "resources";
    } else if (opt === "opt-2") {
      return "data";
    } else if (opt === "opt-3") {
      return "skin_pack";
    }
  };

  const inputVersion = (version) => {
    const vArr = version.split(".");

    return [Number(vArr[0]), Number(vArr[1]), Number(vArr[2])];
  };

  const formatManifest = (jsonString) => {
    try {
      const jsonObject = JSON.parse(jsonString);
      const formattedJsonString = JSON.stringify(jsonObject, null, 2); // Indentation of 2 spaces
      return formattedJsonString;
    } catch (error) {
      console.error("Error parsing or formatting JSON:", error.message);
      return null;
    }
  };

  const renderManifestJson = () => {
    const escapedName = forms[0].name.replace(/"/g, '\\"');
    const escapedDesc = forms[0].description.replace(/"/g, '\\"');

    const filtered = forms.filter((form) => form.type === "dependency");
    const deps = filtered.map((dep) => {
      return {
        type: inputOption(dep.selectedOpt),
        uuid: dep.uuid,
        version: inputVersion(dep.version),
        description: dep.description
      };
    });

    manifest.header.name = escapedName;
    manifest.header.uuid = generateUUID();
    manifest.header.version = inputVersion(forms[0].version);
    manifest.header.description = escapedDesc;

    manifest.modules[0].type = inputOption(forms[0].selectedOpt);
    manifest.modules[0].uuid = generateUUID();
    manifest.modules[0].version = inputVersion(forms[0].version);
    manifest.modules[0].description = escapedDesc;

    if (filtered.length === 0) {
      delete manifest.dependencies;
    } else {
      manifest.dependencies = deps;
    }

    if (forms[1].name === "" && forms[1].link === "") {
      delete manifest.metadata;
    } else {
      manifest.metadata = {};
      manifest.metadata.authors = [];
      manifest.metadata.authors[0] = forms[1].name;
      manifest.metadata.url = forms[1].link;
    }

    const jsonString = JSON.stringify(manifest);

    const formattedManifest = formatManifest(jsonString);
    textareaRef.current.value = formattedManifest;
  };

  const handleRefresh = () => {
    textareaRef.current.value = "";
    setErrorsActivated(false);
    setIsAdvOpen(false);
    setForms([
      {
        id: "r",
        type: "required",
        name: "",
        selectedOpt: "",
        description: "",
        version: ""
      },
      {
        id: "m",
        type: "metadata",
        name: "",
        link: ""
      }
    ]);
    if (addDepsRef && addDepsRef.current) {
      addDepsRef.current.classList.remove("add-dependency-btn");
      addDepsRef.current.innerHTML = "<p>Create Dependency</p>";
    }
  };

  const handleCopy = () => {
    if (textareaRef.current) {
      const textToCopy = textareaRef.current.value;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          copiedRef.current.style.display = "block";
          handleRefresh();
          setTimeout(() => {
            copiedRef.current.style.display = "none";
          }, 2000);
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    }
  };

  const handleDownload = () => {
    const jsonText = textareaRef.current.value;
    if (jsonText) {
      const jsonBlob = new Blob([jsonText], { type: "application/json" });
      const url = URL.createObjectURL(jsonBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "manifest.json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleRefresh();
    }
  };

  useEffect(() => {
    const hasErrors = displayErrors() !== null;

    if (!hasErrors) {
      renderManifestJson();
      setErrorsActivated(false);
    }
  }, [generateClicked]);

  return (
    <section className="container generate-section">
      <button
        type="button"
        className="minecraft-button"
        onClick={() => {
          setErrorsActivated(true);
          setGenerateClicked(!generateClicked);
        }}
      >
        <p>Generate</p>
      </button>
      <div ref={errorsRef} className="missing">
        {errorsActivated && displayErrors()}
      </div>
      <p className="heading manifest-json-txt">MANIFEST.JSON</p>
      <textarea disabled ref={textareaRef} placeholder="Generate..."></textarea>
      <button
        className="minecraft-button"
        onClick={() => {
          handleCopy();
          setCopyClicked(!copyClicked);
        }}
      >
        <p>Copy</p>
      </button>
      <button type="button" className="minecraft-button download-btn" onClick={handleDownload}>
        <p>Download</p>
      </button>
    </section>
  );
};

export default GenerateSection;
