import React, { useRef, useState, useEffect } from "react";
import DefaultIcon from "../images/icons/dropdown-icon-default.svg";
import CloseIcon from "../images/icons/Close.svg";
import Form from "./Form";

const AdvanceMode = ({
  forms,
  setForms,
  isAdvOpen,
  setIsAdvOpen,
  textareaRef,
  addDepsRef,
  errorsRef,
  setErrorsActivated,
  displayErrors,
  scrollToTarget
}) => {
  const [removed, setRemoved] = useState(false);

  const handleAddDependency = () => {
    if (displayErrors() === null) {
      textareaRef.current.value = "";
      setForms((prev) => {
        return [
          ...prev,
          {
            type: "dependency",
            id: forms.length - 1,
            selectedOpt: "",
            description: "",
            uuid: "",
            version: ""
          }
        ];
      });
      if (addDepsRef && addDepsRef.current) {
        addDepsRef.current.innerHTML = "<p>+</p>";
        addDepsRef.current.classList.add("add-dependency-btn");
      }
      setErrorsActivated(false);
    } else {
      setErrorsActivated(true);
      scrollToTarget(errorsRef);
    }
  };

  const handleHide = () => {
    textareaRef.current.value = "";
    setForms([
      forms[0],
      {
        id: "m",
        type: "metadata",
        name: "",
        link: ""
      }
    ]);
    if (addDepsRef && addDepsRef.current) {
      addDepsRef.current.classList.remove("add-dependency-btn");
      addDepsRef.current.innerHTML = "<p>Create Dependency</p>";
    }
    setIsAdvOpen(!isAdvOpen);
  };

  const handleRemove = (id) => {
    const filtered = forms.filter((form) => form.id !== id);
    setForms(filtered);
  };

  useEffect(() => {
    const updatedForms = forms.map((form, index) => {
      return form.type === "dependency" ? { ...form, id: (index - 1).toString() } : form;
    });
    setForms(updatedForms);
  }, [removed]);

  return (
    <section className={`container advance-mode ${isAdvOpen ? "flex" : "hidden"}`}>
      <div className="creator-info children-spacing">
        <p className="heading">Creator's Info (Metadata)</p>
        <Form id="m" type="metadata" textareaRef={textareaRef} forms={forms} setForms={setForms} />
      </div>

      <div className="dependency__wrapper children-spacing">
        {forms.map((form) => {
          if (form.type === "dependency") {
            return (
              <div key={form.id} className="dependency children-spacing relative">
                <p className="heading">{`Dependency [${form.id}]`}</p>
                <div
                  className="absolute top-3 right-2"
                  onClick={() => {
                    handleRemove(form.id);
                    setRemoved(!removed);
                  }}
                >
                  <img className="w-5" src={CloseIcon} />
                </div>
                <Form id={form.id} type="dependency" forms={forms} setForms={setForms} textareaRef={textareaRef} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>

      <button
        ref={addDepsRef}
        type="button"
        className="minecraft-button"
        onClick={() => {
          handleAddDependency();
        }}
      >
        <p>Create Dependency</p>
      </button>

      <div className="hide-icon" onClick={handleHide}>
        <div>
          <img src={DefaultIcon} />
        </div>
      </div>
    </section>
  );
};

export default AdvanceMode;
