import React from "react";
import AdvanceModeBtn from "./AdvanceModeBtn";
import SelectElement from "./SelectElement";

const Form = ({
  id,
  type,
  forms,
  setForms,
  isAdvOpen,
  setIsAdvOpen,
  textareaRef,
  addDepsRef,
  errorsRef,
  setErrorsActivated,
  displayErrors,
  scrollToTarget
}) => {
  const saveData = (e) => {
    textareaRef.current.value = "";
    const { name, value } = e.target;
    setForms((prevForms) => {
      return prevForms.map((form) => (form.id === id ? { ...form, [name]: value } : form));
    });
  };

  return (
    <>
      {type !== "dependency" && (
        <div className="input-wrap">
          <p>Name</p>
          <input name="name" value={forms.find((form) => form.id === id)?.name || ""} onChange={saveData} />
        </div>
      )}

      {type === "metadata" && (
        <div className="input-wrap">
          <p>Link</p>
          <input name="link" value={forms.find((form) => form.id === id)?.link || ""} onChange={saveData} />
        </div>
      )}

      {type !== "metadata" && (
        <>
          <SelectElement id={id} forms={forms} setForms={setForms} textareaRef={textareaRef} />

          <div className="input-wrap">
            <p>Description</p>
            <input
              name="description"
              value={forms.find((form) => form.id === id)?.description || ""}
              onChange={saveData}
            />
          </div>

          {type === "dependency" && (
            <div className="input-wrap">
              <p>UUID</p>
              <input name="uuid" value={forms.find((form) => form.id === id)?.uuid || ""} onChange={saveData} />
            </div>
          )}

          <div className="input-wrap">
            <p>Version</p>
            <input
              placeholder="1.0.0"
              name="version"
              value={forms.find((form) => form.id === id)?.version || ""}
              onChange={saveData}
            />
          </div>
        </>
      )}

      {type === "required" && (
        <AdvanceModeBtn
          isAdvOpen={isAdvOpen}
          setIsAdvOpen={setIsAdvOpen}
          forms={forms}
          setForms={setForms}
          textareaRef={textareaRef}
          addDepsRef={addDepsRef}
          errorsRef={errorsRef}
          setErrorsActivated={setErrorsActivated}
          displayErrors={displayErrors}
          scrollToTarget={scrollToTarget}
        />
      )}
    </>
  );
};

export default Form;
