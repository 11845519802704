import React, { useState, useEffect, useRef } from "react";
/*import About from './components/About';
import MobileNavMenu from './components/MobileNavMenu';*/
import Header from "./components/Header";
import Form from "./components/Form";
import AdvanceMode from "./components/AdvanceMode";
import GenerateSection from "./components/GenerateSection";

const App = () => {
  const [isAdvOpen, setIsAdvOpen] = useState(false);
  const [errorsActivated, setErrorsActivated] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const errorsRef = useRef(null);
  const textareaRef = useRef(null);
  const addDepsRef = useRef(null);
  const copiedRef = useRef(null);
  /*const burgerIcon = useRef(null);
  const mobileNavWrapper = useRef(null);
  const mobileNav = useRef(null);
  const wrappRef = useRef(null);*/

  const isValidVersion = (version) => {
    const regex = /^\d+\.\d+\.\d+$/;
    return regex.test(version);
  };

  const isValidUUID = (uuid) => {
    const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidPattern.test(uuid);
  };

  const scrollToTarget = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const displayErrors = () => {
    const errors = forms.flatMap((form) => {
      if (form.type === "required") {
        const requiredErrors = [];
        if (form.name === "") {
          requiredErrors.push("× Required Name is Missing");
        }
        if (form.selectedOpt === "") {
          requiredErrors.push("× Put the Required Pack Type");
        }
        if (!isValidVersion(form.version)) {
          requiredErrors.push("× Invalid Required Version Format");
        }
        return requiredErrors;
      } else if (form.type === "dependency" && isAdvOpen) {
        const dependencyErrors = [];
        if (form.selectedOpt === "") {
          dependencyErrors.push(`× Put a Pack Type in Dependency[${form.id}]`);
        }
        if (!isValidUUID(form.uuid)) {
          dependencyErrors.push(`× Invalid UUID in Dependency[${form.id}]`);
        }
        if (!isValidVersion(form.version)) {
          dependencyErrors.push(`× Invalid Version Format in Dependency[${form.id}]`);
        }
        return dependencyErrors;
      }
      return [];
    });

    if (errors.length > 0) {
      return errors.map((error, index) => <p key={index}>{error}</p>);
    } else {
      return null;
    }
  };

  const [forms, setForms] = useState([
    {
      id: "r",
      type: "required",
      name: "",
      selectedOpt: "",
      description: "",
      version: ""
    },
    {
      id: "m",
      type: "metadata",
      name: "",
      link: ""
    }
  ]);

  return (
    <div className="App">
      <div ref={copiedRef} className="copied">
        <p>Copied</p>
      </div>
      {/*<About isAboutOpen={isAboutOpen} setIsAboutOpen={setIsAboutOpen}/>
      <MobileNavMenu isNavMenuOpen={isNavMenuOpen} setIsNavMenuOpen={setIsNavMenuOpen} isAboutOpen={isAboutOpen} setIsAboutOpen={setIsAboutOpen} burgerIcon={burgerIcon} mobileNavWrapper={mobileNavWrapper} mobileNav={mobileNav} wrappRef={wrappRef} />*/}
      {/*<Header isNavMenuOpen={isNavMenuOpen} setIsNavMenuOpen={setIsNavMenuOpen} burgerIcon={burgerIcon} mobileNavWrapper={mobileNavWrapper} mobileNav={mobileNav} wrappRef={wrappRef} */}
      <Header />
      <main>
        <section className="container section required-section children-spacing">
          <Form
            id="r"
            type="required"
            forms={forms}
            setForms={setForms}
            isAdvOpen={isAdvOpen}
            setIsAdvOpen={setIsAdvOpen}
            textareaRef={textareaRef}
            addDepsRef={addDepsRef}
            errorsRef={errorsRef}
            setErrorsActivated={setErrorsActivated}
            displayErrors={displayErrors}
            scrollToTarget={scrollToTarget}
          />
        </section>

        <AdvanceMode
          forms={forms}
          setForms={setForms}
          isAdvOpen={isAdvOpen}
          setIsAdvOpen={setIsAdvOpen}
          textareaRef={textareaRef}
          addDepsRef={addDepsRef}
          errorsRef={errorsRef}
          setErrorsActivated={setErrorsActivated}
          displayErrors={displayErrors}
          scrollToTarget={scrollToTarget}
        />
        <GenerateSection
          forms={forms}
          setForms={setForms}
          isAdvOpen={isAdvOpen}
          setIsAdvOpen={setIsAdvOpen}
          errorsActivated={errorsActivated}
          setErrorsActivated={setErrorsActivated}
          textareaRef={textareaRef}
          addDepsRef={addDepsRef}
          copiedRef={copiedRef}
          errorsRef={errorsRef}
          displayErrors={displayErrors}
        />
      </main>
    </div>
  );
};

export default App;
